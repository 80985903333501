import React, { useState, createRef } from "react";
import { useNavigate } from "react-router-dom";
import Header from "components/Header";
import dayjs from "dayjs";
import InsertIcon from "@mui/icons-material/FileCopy";
import SearchIcon from "@mui/icons-material/Search";
import SaveIcon from "@mui/icons-material/Save";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import PointOfSaleIcon from "@mui/icons-material/PointOfSale";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { format } from "date-fns";
import {
  useGetParametrosQuery,
  useCondicionesInsertarTMutation,
  useGetSolicitudesDOIQuery,
} from "state/api";
import {
  Box,
  Button,
  TextField,
  Select,
  FormControl,
  InputLabel,
  Slide,
  MenuItem,
  Snackbar,
  CircularProgress,
  Alert,
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
} from "@mui/material";

const Simulador = () => {
  const navigate = useNavigate();
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const [cronograma, setCronograma] = useState(null);
  const [open, setOpen] = useState(false);
  const handlePopupClose = () => {
    setOpen(false);
  };

  const { data: productosData } = useGetParametrosQuery({
    Campo1: "Etiqueta",
    Valor1: "PRODUCTOS",
    Campo2: "IdPadre",
    Valor2: "2",
  });
  const { data: amortizacionData } = useGetParametrosQuery({
    Campo1: "Etiqueta",
    Valor1: "AMORTIZACION",
    Campo2: "IdPadre",
    Valor2: "12",
  });
  const { data: fechapagoData } = useGetParametrosQuery({
    Campo1: "Etiqueta",
    Valor1: "FECHAPAGO",
    Campo2: "IdPadre",
    Valor2: "12",
  });

  const [datos, setDatos] = useState({
    IdSol: 0,
    DOI: "",
    Nombres: "",
    FecDesem: dayjs(),
    FecFin: dayjs(),
    MtoAprob: "",
    MtoOpe: 0.0,
    MtoSegDe: 0.0,
    Frecuencia: "FRMES",
    NumCuotas: "",
    TipoFecPago: "FDES",
    TipoAmor: "SUSA",
    TEA: 0.0,
    TEM: "",
    Crono: [],
    Estado: "ACT",
    UsMod: 1,
    TipoProd:
      productosData && productosData.length > 0
        ? productosData.find((parametro) => parametro.Valor === "CPER").Valor
        : "",
  });
  const [CondicionesInsertar] = useCondicionesInsertarTMutation();
  const guardarCondiciones = (e) => {
    e.preventDefault();

    setError(null);
    setSuccess(false);

    if (
      !datos.MtoAprob ||
      !datos.NumCuotas ||
      !datos.TEM
    ) {
      setError("Todos los campos son obligatorios");
      return;
    }
    if (!/^\d{8}$/.test(datos.DOI)) {
      setError("El DOI debe tener 8 dígitos");
      return;
    }
    if (!/^\d+$/.test(datos.MtoAprob)) {
      setError("El monto préstamo debe ser un número");
      return;
    }
    setError(null);
    setOpen(true); // Mostrar el popup de confirmación
  };

  const handlePopupAccept = () => {
    setOpen(false); // Cerrar el popup
    // Aquí ejecutas el submit del formulario
    //console.log(datos);
    CondicionesInsertar(datos)
      .unwrap()
      .then((data) => {
        setSuccess(true);
        //console.log("La solicitud se insertó correctamente", data);
      })
      .catch((error) => {
        setError(error.data.error);
        //console.log("La solicitud falló", error.data.error);
      });
  };

  const handleClose = (reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSuccess(false);
  };
  const handleFechaChange = (value) => {
    setDatos({
      ...datos,
      FecDesem: value,
    });
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setDatos((prev) => ({ ...prev, [name]: value }));
  };
  const snackbarRef = createRef();

  const generarCronograma = () => {
    const { FecDesem, TEM, NumCuotas = 0, TipoAmor, MtoAprob, Frecuencia } = datos;
    const FechaPago = new Date(FecDesem);
    const fechaDes = new Date(FecDesem);
    let MtoSaldo = MtoAprob ? Math.round(MtoAprob * 100) / 100 : 0.0;
    let MtoAbono = 0;
    let tea = (TEM / 100) * 12;
    const tasaDia = tea / 360;
    const pagos = [];
    let Dias = Frecuencia === 'FRQUI' ? 15 : 30;
    let MtoCuota =
      (MtoAprob * TEM) / 100 / (1 - Math.pow(1 + TEM / 100, -NumCuotas));
    let MtoInt = 0;
    let FecPagoS;
    let FecPago;
    for (let i = 1; i <= NumCuotas; i++) {
      const NumCuota = i;
      if (Frecuencia === 'FRQUI') {
        // Ajustar la fecha para agregar 15 días en lugar de un mes
        FechaPago.setDate(FechaPago.getDate() + 15);
      } else {
        // Agregar un mes
        FechaPago.setMonth(FechaPago.getMonth() + 1);
      }

      if (TipoAmor.toString() === "SUSA") {
        Dias = calcularDias(fechaDes, FechaPago);
        MtoInt = MtoSaldo * tasaDia * Dias;
        MtoCuota = MtoInt + MtoAbono;

        if (i.toString() === NumCuotas.toString()) {
          MtoCuota = MtoCuota + MtoSaldo;
          MtoAbono = MtoSaldo;
          MtoSaldo = 0.0;
        }
      } else {
        MtoInt = MtoSaldo * tasaDia * Dias;
        MtoAbono = MtoCuota - MtoInt;
      }
      if (Frecuencia === 'FRQUI') {
        // Ajustar la fecha para agregar 15 días en lugar de un mes
        fechaDes.setDate(fechaDes.getDate() + 15);
      } else {
        // Agregar un mes
        fechaDes.setMonth(fechaDes.getMonth() + 1);
      }

      
      FecPago = format(FechaPago, "yyyy-MM-dd HH:mm:ss.SSS");
      FecPagoS = FechaPago.toLocaleDateString("es-ES", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
      });

      const pago = {
        NumCuota,
        FecPagoS,
        FecPago,
        Dias,
        MtoInt,
        MtoAbono,
        MtoCuota,
        MtoSaldo,
      };
      pagos.push(pago);
      console.log(pagos);
      MtoSaldo -= MtoAbono;
    }
    setCronograma(pagos);
    setDatos({
      ...datos,
      Crono: pagos, //JSON.stringify(pagos),
      TEA: tea * 100,
      FecFin: format(FechaPago, "yyyy-MM-dd HH:mm:ss.SSS"),
    });
  };

  const calcularDias = (fechaInicio, fechaFin) => {
    const oneDay = 24 * 60 * 60 * 1000; // Milliseconds in a day
    const start = new Date(fechaInicio);
    const end = new Date(fechaFin);
    return Math.round(Math.abs((start - end) / oneDay));
  };

  const { data: solicitudDOIData } = useGetSolicitudesDOIQuery(datos.DOI, {
    skip: !datos.DOI,
  });
  const buscarSolicitudDOI = async () => {
    try {
      setError(null);
      if (datos.DOI !== "") {
        if (solicitudDOIData.length > 0) {
          setDatos((prev) => ({
            ...prev,
            Nombres:
              solicitudDOIData[0]?.Titular +
              " | " +
              solicitudDOIData[0]?.IdSol || "",
            TipoProd: solicitudDOIData[0].TipoProd,
            MtoAprob: solicitudDOIData[0].MtoSol,
            IdSol: solicitudDOIData[0]?.IdSol,
          }));
        } else {
          setError("No se encontró registros");
          return;
        }
      } else {
        setError("Debe ingresar DOI");
        return;
      }
    } catch (error) {
      setError(null);
      console.error("Error al realizar la búsqueda:", error);
    }
  };

  return (
    <Box m="1.5rem 2.5rem">
      <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
        <Box sx={{ flexGrow: 1 }}>
          <Header title="SIMULADOR" subtitle="Simulador de préstamos" />
        </Box>
        <Box sx={{ flexShrink: 0 }}>
          <Button
            variant="contained"
            startIcon={<InsertIcon />}
            onClick={() => {
              navigate("/simulador");
              window.location.reload();
            }}
          >
            Nueva Simulación
          </Button>
        </Box>
      </Box>
      <br></br>

      <Box height="80vh" sx={[{ maxWidth: "850px", margin: "0 auto" }]}>
        <form onSubmit={guardarCondiciones}>
          {error && (
            <Alert severity="error" sx={{ mt: 2 }}>
              {error}
            </Alert>
          )}

          <Box sx={{ display: "flex", alignItems: "center" }}>
            <TextField
              label="DOI"
              name="DOI"
              value={datos.DOI}
              onChange={handleChange}
              fullWidth
              sx={{ mb: 2, marginRight: 1 }}
            />
            <Button
              sx={{ mb: 2, height: "100%", minWidth: "auto" }}
              variant="contained"
              color="primary"
              startIcon={<SearchIcon />}
              onClick={buscarSolicitudDOI}
            >
              Buscar
            </Button>
          </Box>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <TextField
              label="Nombres | ID"
              name="Nombres"
              value={datos.Nombres}
              fullWidth
              sx={{ mb: 2, marginRight: 2 }}
            />
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="Fecha desembolso"
                name="FecDesem"
                format="DD/MM/YYYY"
                value={datos.FecDesem}
                onChange={handleFechaChange}
                slotProps={{ textField: { variant: "outlined" } }}
                sx={{ mb: 2 }}
              />
            </LocalizationProvider>
          </Box>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <FormControl
              fullWidth
              variant="filled"
              sx={{ mb: 2, marginRight: 2 }}
            >
              <InputLabel id="tipoProd-label">Tipo de producto</InputLabel>
              <Select
                value={datos.TipoProd}
                onChange={handleChange}
                name="TipoProd"
                labelId="tipoProd-label"
              >
                {productosData && productosData.length > 0 ? (
                  productosData.map((parametro) => (
                    <MenuItem
                      key={parametro.IdParametro}
                      value={parametro.Valor}
                    >
                      {parametro.Descripcion}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem value="">Cargando...</MenuItem>
                )}
              </Select>
            </FormControl>
            <TextField
              label="Tasa mensual (%)"
              name="TEM"
              value={datos.TEM}
              onChange={handleChange}
              fullWidth
              sx={{ mb: 2 }}
            />
          </Box>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <TextField
              label="Monto préstamo"
              name="MtoAprob"
              value={datos.MtoAprob}
              onChange={handleChange}
              fullWidth
              sx={{ mb: 2, marginRight: 2 }}
            />
            <TextField
              label="Monto operaciones"
              name="MtoOpe"
              value={datos.MtoOpe}
              onChange={handleChange}
              fullWidth
              sx={{ mb: 2 }}
            />
          </Box>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <TextField
              label="Monto seguro"
              name="MtoSegDe"
              value={datos.MtoSegDe}
              onChange={handleChange}
              fullWidth
              sx={{ mb: 2, marginRight: 2 }}
            />
            <TextField
              label="Número de cuotas"
              name="NumCuotas"
              value={datos.NumCuotas}
              onChange={handleChange}
              fullWidth
              sx={{ mb: 2 }}
            />
          </Box>
          <Box sx={{ display: "flex", alignItems: "center" }}>

            <FormControl
              fullWidth
              variant="filled"
              sx={{ mb: 2, marginRight: 2 }}
            >
              <InputLabel id="tipoAmor-label">
                Sistema de amortización
              </InputLabel>
              <Select
                value={datos.TipoAmor || "SUSA"}
                onChange={handleChange}
                name="TipoAmor"
                labelId="tipoAmor-label"
              >
                {amortizacionData && amortizacionData.length > 0 ? (
                  amortizacionData.map((parametro) => (
                    <MenuItem
                      key={parametro.IdParametro}
                      value={parametro.Valor}
                    >
                      {parametro.Descripcion}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem value="">Cargando...</MenuItem>
                )}
              </Select>
            </FormControl>
            <FormControl fullWidth variant="filled" sx={{ mb: 2, marginRight: 2 }}>
              <InputLabel id="Frecuencia-label">Frecuencia de pago</InputLabel>
              <Select
                value={datos.Frecuencia || "FRMES"}
                onChange={handleChange}
                name="Frecuencia"
                labelId="Frecuencia-label"
              >
                <MenuItem value="FRMES">Mensual</MenuItem>
                <MenuItem value="FRQUI">Quincenal</MenuItem>
                <MenuItem value="FRSEM">Semanal</MenuItem>
              </Select>
            </FormControl>
            <FormControl fullWidth variant="filled" sx={{ mb: 2 }}>
              <InputLabel id="tipoFecPago-label">Día de Pago</InputLabel>
              <Select
                value={datos.TipoFecPago || "FDES"}
                onChange={handleChange}
                name="TipoFecPago"
                labelId="tipoFecPago-label"
              >
                {fechapagoData && fechapagoData.length > 0 ? (
                  fechapagoData.map((parametro) => (
                    <MenuItem
                      key={parametro.IdParametro}
                      value={parametro.Valor}
                    >
                      {parametro.Descripcion}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem value="">Cargando...</MenuItem>
                )}
              </Select>
            </FormControl>
          </Box>

          <Button
            sx={{ mb: 2 }}
            variant="contained"
            color="primary"
            fullWidth
            startIcon={<PointOfSaleIcon />}
            onClick={generarCronograma}
          >
            Simular cronograma
          </Button>

          {cronograma && cronograma.length > 0 && (
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ fontWeight: 700 }}>#Cuota</TableCell>
                    <TableCell sx={{ fontWeight: 700 }}>
                      Fecha de Pago
                    </TableCell>
                    <TableCell sx={{ fontWeight: 700 }}>Días</TableCell>
                    <TableCell sx={{ fontWeight: 700 }}>Interés</TableCell>
                    <TableCell sx={{ fontWeight: 700 }}>
                      Abono Capital
                    </TableCell>
                    <TableCell sx={{ fontWeight: 700 }}>
                      Monto de Cuota
                    </TableCell>
                    <TableCell sx={{ fontWeight: 700 }}>
                      Saldo Capital
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {cronograma.map((pago) => (
                    <TableRow key={pago.NumCuota}>
                      <TableCell>{pago.NumCuota}</TableCell>
                      <TableCell>{pago.FecPagoS}</TableCell>
                      <TableCell>{pago.Dias}</TableCell>
                      <TableCell>{pago.MtoInt.toFixed(2)}</TableCell>
                      <TableCell>{pago.MtoAbono.toFixed(2)}</TableCell>
                      <TableCell>{pago.MtoCuota.toFixed(2)}</TableCell>
                      <TableCell>{pago.MtoSaldo.toFixed(2)}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}

          <Button
            sx={{ mt: 2 }}
            variant="contained"
            color="primary"
            type="submit"
            fullWidth
            startIcon={<SaveIcon />}
            disabled={CondicionesInsertar.isLoading}
          >
            {CondicionesInsertar.isLoading ? (
              <CircularProgress size={24} color="inherit" />
            ) : (
              "Guardar Condiciones"
            )}
          </Button>

          <Snackbar
            TransitionComponent={(props) => (
              <Slide ref={snackbarRef} {...props} direction="down" />
            )}
            open={success}
            autoHideDuration={6000}
            onClose={handleClose}
            sx={{ mt: 2 }}
          >
            <Alert onClose={handleClose} severity="success">
              Condiciones y cronograma guardado con éxito
            </Alert>
          </Snackbar>

          <Dialog open={open} onClose={handlePopupClose}>
            <DialogTitle>
              <Grid container direction="row" alignItems="center">
                <Grid item>
                  <Box
                    sx={{
                      color: "text.primary",
                      fontSize: 28,
                      fontWeight: "medium",
                      textAlign: "center",
                      alignItems: "center",
                    }}
                  >
                    Condiciones
                  </Box>
                </Grid>
                <Grid item>
                  <WarningAmberIcon sx={{ fontSize: 28, color: "#f8bb86" }} />
                </Grid>
              </Grid>
            </DialogTitle>

            <DialogContent>
              <DialogContentText component="div">
                <Box
                  sx={{
                    color: "text.primary",
                    fontSize: 16,
                    fontWeight: "small",
                    textAlign: "center",
                  }}
                >
                  ¿Estás seguro de que quieres guardar las condiciones?
                </Box>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={handlePopupAccept}
                variant="contained"
                color="success"
              >
                Sí
              </Button>
              <Button
                onClick={handlePopupClose}
                variant="contained"
                color="error"
              >
                No
              </Button>
            </DialogActions>
          </Dialog>
        </form>
      </Box>
    </Box>
  );
};

export default Simulador;

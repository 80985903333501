import React, { useRef } from "react";
import { useParams } from "react-router-dom";
import { useGetPrestamosIDQuery, useGetParametrosQuery } from "state/api";
import { styled } from "@mui/material/styles";
import { format } from "date-fns";
import { utcToZonedTime } from "date-fns-tz";
import { fCurrency } from "utils/format-number";
import {
  Box,
  Button,
  useTheme,
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Grid,
  Typography,
} from "@mui/material";
import Header from "components/Header";
import html2pdf from "html2pdf.js/dist/html2pdf.min";
import PrintIcon from "@mui/icons-material/Print";

const Cronograma = () => {
  const theme = useTheme();
  const page = 0;
  const pageSize = 5;
  const fechaSistema = new Date();
  const GridItem = styled(Grid)(({ theme }) => ({
    width: "100%",
    display: "flex",
    flexDirection: "column",
    textAlign: "center",
    marginBottom: "10px",
    [theme.breakpoints.up("sm")]: {
      width: "50%",
      textAlign: "left",
      flexDirection: "row",
    },
  }));
  const TypographyStyledTitle = styled(Typography)(({ theme }) => ({
    variant: "subtitle2",
    mb: 1,
    color: "#616161",
    width: "100%",
    fontWeight: "bold",
    [theme.breakpoints.up("sm")]: {
      width: "50%",
    },
  }));
  const TypographyStyledText = styled(Typography)(({ theme }) => ({
    variant: "subtitle2",
    mb: 1,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "50%",
    },
  }));

  const { IdCond = 0 } = useParams();

  const { data } = useGetPrestamosIDQuery({
    page,
    pageSize,
    IdCond,
  });

  let prestamosDataID = [];
  if (data) {
    prestamosDataID = data.records;
  }

  let cronogramaData = [];

  const { data: productosData } = useGetParametrosQuery({
    Campo1: "Etiqueta",
    Valor1: "PRODUCTOS",
    Campo2: "IdPadre",
    Valor2: "2",
  });

  const { data: amortizacionData } = useGetParametrosQuery({
    Campo1: "Etiqueta",
    Valor1: "AMORTIZACION",
    Campo2: "IdPadre",
    Valor2: "12",
  });

  const { data: monedaData } = useGetParametrosQuery({
    Campo1: "Etiqueta",
    Valor1: "MONEDA",
    Campo2: "IdPadre",
    Valor2: "12",
  });

  if (prestamosDataID && prestamosDataID.length > 0) {
    cronogramaData = JSON.parse(prestamosDataID[0].Cronograma);
    console.log(cronogramaData);
  }
  const ref = useRef();

  function handlePdf() {
    let element = ref.current;
    let fecha = new Intl.DateTimeFormat("es-PE", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    }).format(new Date(prestamosDataID[0].FecDesem));

    fecha = fecha.replace(/[-/]/g, "");
    let opt = {
      margin: 1,
      filename: prestamosDataID[0].Nombres + prestamosDataID[0].ApePat + fecha,
      image: { type: "jpeg", quality: 1 },
      html2canvas: { scale: 1.2 },
      jsPDF: { unit: "mm", format: "a4", orientation: "l" },
    };
    html2pdf().set(opt).from(element).save();
  }

  return (
    <Box>
      <Box m="1.5rem 2.5rem" ref={ref}>
        <Box
          sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
        >
          <Box sx={{ flexGrow: 1 }}>
            <Header title="Cronograma de pagos" subtitle="Plataforma v0.3" />
          </Box>
          <Box sx={{ flexShrink: 0 }}>
            <img src="/home_logo_38.png" alt="Logo de prestaMu" />
          </Box>
        </Box>
        <Box sx={[{ maxWidth: "950px", margin: "0 auto" }]}>
          {prestamosDataID && prestamosDataID.length > 0 && (
            <>
              <Grid container sx={[{ margin: "30px auto 0" }]}>
                <GridItem
                  item
                  sx={[{ border: "0.2rem solid #ce65a4" }]}
                  xs={12}
                ></GridItem>
                <GridItem item xs={12}></GridItem>
                <GridItem item xs={12}>
                  <Grid container spacing={0}>
                    <Grid item xs={3}>
                      <TypographyStyledTitle>Titular:</TypographyStyledTitle>
                    </Grid>
                    <Grid item xs={6}>
                      {prestamosDataID[0].Nombres} {prestamosDataID[0].ApePat}{" "}
                      {prestamosDataID[0].ApeMat}
                    </Grid>
                    <Grid item xs={1}>
                      <TypographyStyledTitle>DOI:</TypographyStyledTitle>
                    </Grid>
                    <Grid item xs={2}>
                      <TypographyStyledText>
                        {prestamosDataID[0].DOI}
                      </TypographyStyledText>
                    </Grid>
                  </Grid>
                </GridItem>
                <GridItem item>
                  <TypographyStyledTitle>Producto:</TypographyStyledTitle>
                  <TypographyStyledText>
                    {productosData && productosData.length > 0
                      ? productosData.find(
                          (parametro) =>
                            parametro.Valor === prestamosDataID[0].TipoProd
                        ).Descripcion
                      : ""}
                  </TypographyStyledText>
                </GridItem>
                <GridItem item>
                  <TypographyStyledTitle>Moneda:</TypographyStyledTitle>
                  <TypographyStyledText>
                    {monedaData && monedaData.length > 0
                      ? monedaData.find(
                          (parametro) =>
                            parametro.Valor === prestamosDataID[0].TipoMoneda
                        ).Descripcion
                      : ""}
                  </TypographyStyledText>
                </GridItem>
                <GridItem item>
                  <TypographyStyledTitle>Sistema:</TypographyStyledTitle>
                  <TypographyStyledText>
                    {amortizacionData && amortizacionData.length > 0
                      ? amortizacionData.find(
                          (parametro) =>
                            parametro.Valor === prestamosDataID[0].TipoAmor
                        ).Descripcion
                      : ""}
                  </TypographyStyledText>
                </GridItem>
                <GridItem item>
                  <TypographyStyledTitle>Tasa Mensual:</TypographyStyledTitle>
                  <TypographyStyledText>
                    {prestamosDataID[0].TEM}%
                  </TypographyStyledText>
                </GridItem>
                <GridItem item>
                  <TypographyStyledTitle>
                    Monto de Préstamo:
                  </TypographyStyledTitle>
                  <TypographyStyledText>                  
                    {fCurrency(prestamosDataID[0].MtoAprob)}
                  </TypographyStyledText>
                </GridItem>
                <GridItem item>
                  <TypographyStyledTitle>
                    Número de Cuotas:
                  </TypographyStyledTitle>
                  <TypographyStyledText>
                    {prestamosDataID[0].NumCuotas}
                  </TypographyStyledText>
                </GridItem>
                <GridItem item>
                  <TypographyStyledTitle>
                    Fecha Desembolso:
                  </TypographyStyledTitle>
                  <TypographyStyledText>
                    {format(
                      utcToZonedTime(prestamosDataID[0].FecDesem),
                      "dd/MM/yyyy"
                    )}
                  </TypographyStyledText>
                </GridItem>
                <GridItem item>
                  <TypographyStyledTitle>
                    Fecha Vencimiento:
                  </TypographyStyledTitle>
                  <TypographyStyledText>
                    {format(
                      utcToZonedTime(prestamosDataID[0].FecFin),
                      "dd/MM/yyyy"
                    )}
                  </TypographyStyledText>
                </GridItem>
              </Grid>
            </>
          )}
          <Box
            mt="20px"
            sx={{
              "& .MuiTable-root": {
                border: "none",
              },
              "& .MuiTableCell-root": { textAlign: "center" },
              "& .MuiTableHead-root": {
                backgroundColor: theme.palette.background.alt,
              },
              "& .MuiTableHead-root .MuiTableCell-root": {
                fontWeight: 700,
                background: theme.palette.background.alt,
                textAlign: "center",
              },
              "& .MuiTableBody-root .MuiTableCell-root": {},
            }}
          >
            {prestamosDataID && cronogramaData.length > 0 && (
              <>
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>#Cuota</TableCell>
                        <TableCell>Fecha de Pago</TableCell>
                        <TableCell>Días</TableCell>
                        <TableCell>Interés</TableCell>
                        <TableCell>Abono Capital</TableCell>
                        <TableCell>Monto de Cuota</TableCell>
                        <TableCell>Saldo Capital</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {cronogramaData.map((pago) => (
                        <TableRow key={pago.NumCuota}>
                          <TableCell>{pago.NumCuota}</TableCell>
                          <TableCell>
                            {new Intl.DateTimeFormat("es-PE", {
                              year: "numeric",
                              month: "2-digit",
                              day: "2-digit",
                            }).format(new Date(pago.FecPago))}
                          </TableCell>
                          <TableCell>{pago.Dias}</TableCell>
                          <TableCell>{pago.MtoInt.toFixed(2)}</TableCell>
                          <TableCell>{pago.MtoAbono.toFixed(2)}</TableCell>
                          <TableCell>{pago.MtoCuota.toFixed(2)}</TableCell>
                          <TableCell>{pago.MtoSaldo.toFixed(2)}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </>
            )}
          </Box>
        </Box>
        <br></br>
        <Box
          sx={{
            fontSize: 8,
            maxWidth: "950px",
            margin: "0 auto",
            textAlign: "right",
          }}
        >
          Fecha del sistema: {fechaSistema.toString()}
        </Box>
      </Box>
      <br></br>
      <Box sx={{ maxWidth: "950px", margin: "0 auto", textAlign: "center" }}>
        <Button
          variant="contained"
          color="primary"
          startIcon={<PrintIcon />}
          onClick={handlePdf}
        >
          Generar PDF
        </Button>
      </Box>
    </Box>
  );
};

export default Cronograma;

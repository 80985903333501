import { CssBaseline, ThemeProvider } from "@mui/material";
import { createTheme } from "@mui/material/styles";
import { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { themeSettings } from "theme";
import Layout from "scenes/layout";
import Dashboard from "scenes/dashboard";
import Tercero from "scenes/tercero";
import Solicitudes from "scenes/solicitudes/listar";
import SolNuevo from "scenes/solicitudes/nuevo";
import Simulador from "scenes/simulador";
import Prestamos from "scenes/prestamos/listar";
import Cronograma from "scenes/prestamos/cronograma";
import Cobranza from "scenes/cobranza";
import TerceroEditar from "scenes/tercero/editar";
import SignupForm from "scenes/solicitudes/nuevo/form.jsx";
import Login from "components/Login";

function App() {
  const [user, setUser] = useState(null);
  const [secret, setSecret] = useState(null);
  const isAuth = Boolean(user) && Boolean(secret);

  const mode = useSelector((state) => state.global.mode);
  const theme = useMemo(() => createTheme(themeSettings(mode)), [mode]);
  return (
    <div className="app">
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Routes>
            <Route
              path="/login"
              element={
                isAuth ? (
                  <Navigate to="/dashboard" replace />
                ) : (
                  <Login setUser={setUser} setSecret={setSecret} />
                )
              }
            />
            <Route element={<Layout />}>
              <Route
                path="/"
                element={
                  isAuth ? (
                    <Navigate to="/dashboard" />
                  ) : (
                    <Navigate to="/login" />
                  )
                }
              />
              <Route
                path="/dashboard"
                element={
                  isAuth ? (
                    <Dashboard user={user} secret={secret} />
                  ) : (
                    <Navigate to="/login" />
                  )
                }
              />
              <Route
                path="/clientes"
                element={
                  isAuth ? (
                    <Tercero user={user} secret={secret} />
                  ) : (
                    <Navigate to="/login" />
                  )
                }
              />

              <Route
                path="/solicitudes/listar"
                element={
                  isAuth ? (
                    <Solicitudes user={user} secret={secret} />
                  ) : (
                    <Navigate to="/login" />
                  )
                }
              />
              <Route
                path="/solicitudes/nuevo"
                element={
                  isAuth ? (
                    <SolNuevo user={user} secret={secret} />
                  ) : (
                    <Navigate to="/login" />
                  )
                }
              />
              <Route
                path="/solicitudes/form"
                element={
                  isAuth ? (
                    <SignupForm user={user} secret={secret} />
                  ) : (
                    <Navigate to="/login" />
                  )
                }
              />
              <Route
                path="/simulador"
                element={
                  isAuth ? (
                    <Simulador user={user} secret={secret} />
                  ) : (
                    <Navigate to="/login" />
                  )
                }
              />
              <Route
                path="/prestamos/listar"
                element={
                  isAuth ? (
                    <Prestamos user={user} secret={secret} />
                  ) : (
                    <Navigate to="/login" />
                  )
                }
              />
              <Route
                path="/prestamos/cronograma"
                element={
                  isAuth ? (
                    <Cronograma user={user} secret={secret} />
                  ) : (
                    <Navigate to="/login" />
                  )
                }
              />
              <Route
                path="/prestamos/cronograma/:IdCond"
                element={
                  isAuth ? (
                    <Cronograma user={user} secret={secret} />
                  ) : (
                    <Navigate to="/login" />
                  )
                }
              />
              <Route
                path="/cobranza"
                element={
                  isAuth ? (
                    <Cobranza user={user} secret={secret} />
                  ) : (
                    <Navigate to="/login" />
                  )
                }
              />
              <Route
                path="/clientes/editar"
                element={
                  isAuth ? (
                    <TerceroEditar user={user} secret={secret} />
                  ) : (
                    <Navigate to="/login" />
                  )
                }
              />
            </Route>
          </Routes>
        </ThemeProvider>
      </BrowserRouter>
    </div>
  );
}

export default App;

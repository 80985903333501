import React, { useState, useCallback } from "react";
import { Box, useTheme, Tab, Tabs, Container } from "@mui/material";
import Iconify from "components/iconify";
import Header from "components/Header";

const TerceroEditar = () => {
  const theme = useTheme();
  const [currentTab, setCurrentTab] = useState("general");
  const handleChangeTab = useCallback(function (event, newValue) {
    setCurrentTab(newValue);
  }, []);

  const TABS = [
    {
      value: "general",
      label: "General",
      icon: <Iconify icon="solar:user-id-bold" width={24} />,
    },
    {
      value: "cuenta",
      label: "Cuenta",
      icon: <Iconify icon="solar:bill-list-bold" width={24} />,
    },
    {
      value: "rating",
      label: "Rating",
      icon: <Iconify icon="solar:bell-bing-bold" width={24} />,
    },
    {
      value: "security",
      label: "Security",
      icon: <Iconify icon="ic:round-vpn-key" width={24} />,
    },
  ];
  return (
    <Box m="1.5rem 2.5rem">
      <Header title="CLIENTES" subtitle="Editar Cliente" />
      <br></br>
      <Box height="80vh" sx={[{ maxWidth: "850px", margin: "0 auto" }]}>
        <Tabs
          value={currentTab}
          onChange={handleChangeTab}
          sx={{
            mb: { xs: 3, md: 5 },
          }}
        >
          {TABS.map((tab) => (
            <Tab
              key={tab.value}
              label={tab.label}
              icon={tab.icon}
              value={tab.value}
              iconPosition="start"
            />
          ))}
        </Tabs>
      </Box>
    </Box>
  );
};

export default TerceroEditar;

import React, { useState } from "react";
import { Box, useTheme, Button } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useGetSolicitudesQuery } from "state/api";
import Header from "components/Header";
import DataGridCustomToolbar from "components/DataGridCustomToolbar";
import InsertIcon from "@mui/icons-material/FileCopy";

const Transactions = () => {
  const theme = useTheme();

  // values to be sent to the backend
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  //const [sort, setSort] = useState({});
  const [DOI, setSearch] = useState("");

  const [searchInput, setSearchInput] = useState("");
  const { data, isLoading, pageInfo } = useGetSolicitudesQuery({
    page,
    pageSize,
    /*  sort: JSON.stringify(sort),*/
    DOI,
  });

  const [rowCountState, setRowCountState] = React.useState(
    pageInfo?.totalRowCount || 0
  );
  React.useEffect(() => {
    setRowCountState((prevRowCountState) =>
      pageInfo?.totalRowCount !== undefined
        ? pageInfo?.totalRowCount
        : prevRowCountState
    );
  }, [pageInfo?.totalRowCount, setRowCountState]);

  const columns = [
    {
      field: "IdSol",
      headerName: "ID",
      flex: 0.1,
    },
    {
      field: "FecSol",
      headerName: "Fec. Solicitud",
      flex: 0.2,

      renderCell: (params) =>
        new Date(params.value).toLocaleDateString("es-ES", {
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
        }),
    },
    {
      field: "DOI",
      headerName: "DOI",
      flex: 0.2,
    },
    {
      field: "TipoSol",
      headerName: "Tipo",
      flex: 0.25,
    },
    {
      field: "TipoEval",
      headerName: "Eval.",
      flex: 0.35,
    },
    {
      field: "TipoProd",
      headerName: "Prod.",
      flex: 0.35,
    },
    {
      field: "TipoMoneda",
      headerName: "Moneda",
      flex: 0.2,
    },
    {
      field: "MtoSol",
      headerName: "Mto. Sol.",
      flex: 0.2,
    },
    {
      field: "TipoMot",
      headerName: "Motivo",
      flex: 0.7,
    },
  ];

  return (
    <Box m="1.5rem 2.5rem">
      <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
        <Box sx={{ flexGrow: 1 }}>
          <Header title="SOLICITUDES" subtitle="Lista de solicitudes" />
        </Box>
        <Box sx={{ flexShrink: 0 }}>
          <Button variant="contained" startIcon={<InsertIcon />}>
            Nueva Solicitud
          </Button>
        </Box>
      </Box>
      <Box
        height="80vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: theme.palette.primary.light,
          },
          "& .MuiDataGrid-footerContainer": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderTop: "none",
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${theme.palette.secondary[200]} !important`,
          },
        }}
      >
        <DataGrid
          loading={isLoading || !data}
          getRowId={(row) => row.IdSol}
          rows={data || []}
          columns={columns}
          rowCount={rowCountState}
          rowsPerPageOptions={[20, 50]}
          pagination
          page={page}
          pageSize={pageSize}
          paginationMode="server"          
          onPageChange={(newPage) => setPage(newPage)}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}          
          components={{ Toolbar: DataGridCustomToolbar }}
          componentsProps={{
            toolbar: { searchInput, setSearchInput, setSearch },
          }}
        />
      </Box>
    </Box>
  );
};

export default Transactions;

// color design tokens export
export const tokensDark = {
  grey: {
    0: "#ffffff", // Fondo Blanco - Light
    10: "#f6f6f6", // manually adjusted
    50: "#f5f5f5", // Color fondo sidebar y cabeceras - Light (pastel ce65a4 )
    100: "#e0e0e0", // Color fondo grilla - Light
    200: "#c2c2c2", // Gris
    300: "#a3a3a3", // Gris más oscuro
    400: "#858585",  // Más oscuro
    500: "#666666", // Casi negro
    600: "#525252", // Casi Negro
    700: "#3d3d3d", // Negro
    800: "#292929", // Negro
    900: "#141414", // Negro
    1000: "#000000", // Negro
  },
  primary: {
    // blue
    50: "#f5f5f5", //Blanco Humo
    100: "#d3d4de", // Gris
    200: "#a6a9be",  // Gris Oscuro
    300: "#f5f5f5", //Blanco Humo
    400: "#ce65a4", // Fondo Grilla - Dark Rosado
    500: "#9e286f", // Color Sidebar - Dark Rosado Oscuro
    600: "#741b47", // Fondo PrestaMu - Dark Rosado Oscuro +
    700: "#9e286f", // Fondo Select  - Dark Rosado Oscuro
    800: "#0d1025", // Azul Oscuro
    900: "#070812", //Azul  Oscuro casi negro
  },
  secondary: {
    // yellow
    50: "#f0f0f0", // manually adjusted
    100: "#fff6e0", // Titulos lista (Menu) - Dark (Amarillo claro)
    200: "#ffedc2", // Iconos Color - Dark (Amarillo)
    300: "#ffe3a3", // Menu activo - Dark (Amarillo)
    400: "#ffda85", // Titulo cabecera - Dark
    500: "#505050", // Titulo cabecera - Light
    600: "#ce65a4", //Menu Activo, Sub-Titulo - Light (Casi blanco fdd16e)
    700: "#ce65a4", // Iconos - Light (fff3d9)
    800: "#212529", // Casi blanco: Titulos lista, cabecera, cabecera grilla - Light (casi blanco fff3fa)
    900: "#332a14",
  },
};

// function that reverses the color palette
function reverseTokens(tokensDark) {
  const reversedTokens = {};
  Object.entries(tokensDark).forEach(([key, val]) => {
    const keys = Object.keys(val);
    const values = Object.values(val);
    const length = keys.length;
    const reversedObj = {};
    for (let i = 0; i < length; i++) {
      reversedObj[keys[i]] = values[length - i - 1];
    }
    reversedTokens[key] = reversedObj;
  });
  return reversedTokens;
}
export const tokensLight = reverseTokens(tokensDark);

// mui theme settings
export const themeSettings = (mode) => {
  return {
    palette: {
      mode: mode,
      ...(mode === "dark"
        ? {
            // Paleta de colores para dark mode
            primary: {
              ...tokensDark.primary,
              main: tokensDark.primary[400],
              light: tokensDark.primary[400],
            },
            secondary: {
              ...tokensDark.secondary,
              main: tokensDark.secondary[300],
            },
            neutral: {
              ...tokensDark.grey,
              main: tokensDark.grey[500],
            },
            background: {
              default: tokensDark.primary[600],
              alt: tokensDark.primary[500],
            },
          }
        : {
            // Paleta de colores para light mode
            primary: {
              ...tokensLight.primary,
              main: tokensDark.grey[50],
              light: tokensDark.grey[100],
            },
            secondary: {
              ...tokensLight.secondary,
              main: tokensDark.secondary[600],
              light: tokensDark.secondary[700],
            },
            neutral: {
              ...tokensLight.grey,
              main: tokensDark.grey[500],
            },
            background: {
              default: tokensDark.grey[0],
              alt: tokensDark.grey[50],
            },
          }),
    },
    typography: {
      fontFamily: ["Inter", "sans-serif"].join(","),
      fontSize: 12,      
      h1: {
        fontFamily: ["Inter", "sans-serif"].join(","),
        fontSize: 40,
      },
      h2: {
        fontFamily: ["Inter", "sans-serif"].join(","),
        fontSize: 32,
      },
      h3: {
        fontFamily: ["Inter", "sans-serif"].join(","),
        fontSize: 24,
      },
      h4: {
        fontFamily: ["Inter", "sans-serif"].join(","),
        fontSize: 20,
      },
      h5: {
        fontFamily: ["Inter", "sans-serif"].join(","),
        fontSize: 16,
      },
      h6: {
        fontFamily: ["Inter", "sans-serif"].join(","),
        fontSize: 14,
      },
    },
  };
};

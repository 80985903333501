import React from "react";
import { Box, useTheme } from "@mui/material";
import { useGetTerceroQuery } from "state/api";
import Header from "components/Header";
import { DataGrid } from "@mui/x-data-grid";

const Tercero = () => {
  const theme = useTheme();
  
  const { data, isLoading } = useGetTerceroQuery();

  const columns = [
    {
      field: "DOI",
      headerName: "DOI",      
      flex: 0.2,   
    },
    {
      field: "Nombres",
      headerName: "Nombres",
      flex: 0.4,
    },
    {
      field: "ApePat",
      headerName: "Apellido Paterno",
      flex: 0.3,
    },
    {
      field: "ApeMat",
      headerName: "Apellido Materno",
      flex: 0.3,
    },
    {
      field: "Estado",
      headerName: "Estado",
      flex: 0.5,
    },
    {
      field: "NumTel",
      headerName: "Número Teléfono",
      flex: 0.5,
      renderCell: (params) => {
        return params.value ? params.value.replace(/^(\d{3})(\d{3})(\d{4})/, "($1)$2-$3") : "";
      },
    },
    {
      field: "Ocupacion",
      headerName: "Ocupación",
      flex: 0.4,
    },
  ];

  return (
    <Box m="1.5rem 2.5rem">
      <Header title="CLIENTES" subtitle="Lista de Clientes" />
      <Box
        mt="40px"
        height="75vh"                
        sx={{
            "& .MuiDataGrid-root": {
              border: "none",
              '--unstable_DataGrid-headWeight': 600,
            },
            "& .MuiDataGrid-cell": {
              borderBottom: "none",
            },
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: theme.palette.background.alt,
              color: theme.palette.secondary[100],
              borderBottom: "none",               
            },
            "& .MuiDataGrid-virtualScroller": {
              backgroundColor: theme.palette.primary.light,
            },
            "& .MuiDataGrid-footerContainer": {
              backgroundColor: theme.palette.background.alt,
              color: theme.palette.secondary[100],
              borderTop: "none",
            },
            "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
              color: `${theme.palette.secondary[200]} !important`,
            },
          }}
      >
        <DataGrid
          loading={isLoading || !data}
          getRowId={(row) => row.DOI}
          rows={data || []}
          columns={columns}
        />
      </Box>
    </Box>
  );
};

export default Tercero;

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, useTheme, Button } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useGetPrestamosQuery } from "state/api";
import Header from "components/Header";
import DataGridCustomToolbar from "components/DataGridCustomToolbar";
import InsertIcon from "@mui/icons-material/FileCopy";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { format } from "date-fns";
import { utcToZonedTime } from "date-fns-tz";

const Prestamos = () => {
  const theme = useTheme();
  // values to be sent to the backend
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(20);
  //const [sort, setSort] = useState({});
  const [DOI, setSearch] = useState("");
  const [searchInput, setSearchInput] = useState("");
  const { isLoading, data } = useGetPrestamosQuery({
    page,
    pageSize,
    DOI,
  });

  const [rowCountState, setRowCountState] = React.useState(
    data?.totalRowCount || 0
  );
  React.useEffect(() => {
    setRowCountState((prevRowCountState) =>
      data?.totalRowCount !== undefined
        ? data?.totalRowCount
        : prevRowCountState
    );
  }, [data?.totalRowCount, setRowCountState]);

  const columns = [
    {
      field: "IdCond",
      headerName: "ID",
      flex: 0.1,
    },
    {
      field: "FecDesem",
      headerName: "Fec. Desembolso",
      flex: 0.2,
      renderCell: (params) =>                
        format(
          utcToZonedTime(params.value),
          "dd/MM/yyyy")
        ,
    },
    {
      field: "DOI",
      headerName: "DOI",
      flex: 0.2,
    },
    {
      field: "Nombres",
      headerName: "Nombres",
      flex: 0.25,
    },
    {
      field: "ApePat",
      headerName: "Ape. Paterno",
      flex: 0.2,
    },
    {
      field: "ApeMat",
      headerName: "Ape. Materno",
      flex: 0.2,
    },
    {
      field: "MtoAprob",
      headerName: "Mto. Aprob.",
      flex: 0.2,
    },
    {
      field: "NumCuotas",
      headerName: "# Cuotas",
      flex: 0.2,
    },
    {
      field: "TEM",
      headerName: "TEM %",
      flex: 0.2,
    },
    {
      field: "actions",
      headerName: "Cronograma",
      flex: 0.2,
      renderCell: (params) => (
        <Button
          variant="contained"
          color="info"
          endIcon={<CalendarMonthIcon />}
          onClick={() => {
            navigate("/prestamos/cronograma/" + params.row.IdCond);
          }}
        >
          Ver
        </Button>
      ),
    },
  ];
  let rows = [];
  if (data) {
    rows = data.records;
  }
  const navigate = useNavigate();
  return (
    <Box m="1.5rem 2.5rem">
      <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
        <Box sx={{ flexGrow: 1 }}>
          <Header title="Préstamos" subtitle="Lista de préstamos" />
        </Box>
        <Box sx={{ flexShrink: 0 }}>
          <Button
            variant="contained"
            startIcon={<InsertIcon />}
            onClick={() => {
              navigate("/solicitudes/nuevo");
            }}
          >
            Nueva Solicitud
          </Button>
        </Box>
      </Box>
      <Box
        height="80vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: theme.palette.primary.light,
          },
          "& .MuiDataGrid-footerContainer": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderTop: "none",
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${theme.palette.secondary[200]} !important`,
          },
        }}
      >
        <DataGrid
          loading={isLoading || !data}
          getRowId={(row) => row.IdCond}
          rows={rows || []}
          columns={columns}
          rowCount={rowCountState}
          rowsPerPageOptions={[5, 10, 20]}
          pagination
          page={page}
          pageSize={pageSize}          
          paginationMode="server"
          onPageChange={(newPage) => setPage(newPage)}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}          
          components={{ Toolbar: DataGridCustomToolbar }}
          componentsProps={{
            toolbar: { searchInput, setSearchInput, setSearch },
          }}
        />
      </Box>
    </Box>
  );
};

export default Prestamos;

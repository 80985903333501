import React from "react";
import {
  Box,
  Drawer,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
  useTheme,
} from "@mui/material";
import {
  ChevronLeft,
  ChevronRightOutlined,
  HomeOutlined,
  Groups2Outlined,
  ReceiptLongOutlined,
  PointOfSaleOutlined,
  AdminPanelSettingsOutlined,
} from "@mui/icons-material";
import RequestPageOutlinedIcon from "@mui/icons-material/RequestPageOutlined";
import CreditScoreOutlinedIcon from "@mui/icons-material/CreditScoreOutlined";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import FlexBetween from "./FlexBetween";

import { eliminatildes } from "utils/funciones";

const navItems = [
  {
    text: "Dashboard",
    icon: <HomeOutlined />,
  },
  {
    text: "Operaciones",
    icon: null,
  },
  {
    text: "Solicitudes",
    icon: <RequestPageOutlinedIcon />,
    subItems: [
      {
        text: "Nuevo",
        icon: <HomeOutlined />,
        path: "/solicitudes/nuevo",
      },
      {
        text: "Lista",
        icon: <HomeOutlined />,
        path: "/solicitudes/listar",
      },
    ],
  },
  {
    text: "Simulador",
    icon: <ReceiptLongOutlined />,
    path: "/simulador",
  },
  {
    text: "Préstamos",
    icon: <CreditScoreOutlinedIcon />,
    subItems: [
      {
        text: "Lista",
        icon: <CreditScoreOutlinedIcon />,
        path: "/prestamos/listar",
      },
      {
        text: "Cronograma",
        icon: <CreditScoreOutlinedIcon />,
        path: "/prestamos/cronograma",
      },
    ],
  },
  {
    text: "Cobranza",
    icon: <PointOfSaleOutlined />,
    path: "/cobranza",
  },
  {
    text: "Administrativo",
    icon: null,
  },
  {
    text: "Clientes",
    icon: <Groups2Outlined />,
    subItems: [
      {
        text: "Lista",
        icon: <Groups2Outlined />,
        path: "/clientes",
      },
      {
        text: "Editar",
        icon: <Groups2Outlined />,
        path: "/clientes/editar",
      },
    ],
  },
  {
    text: "Parámetros",
    icon: <AdminPanelSettingsOutlined />,
  },
];

const Sidebar = ({
  user,
  drawerWidth,
  isSidebarOpen,
  setIsSidebarOpen,
  isNonMobile,
}) => {
  const { pathname } = useLocation();
  const [active, setActive] = useState("");
  const [openItems, setOpenItems] = useState([]);
  const navigate = useNavigate();
  const theme = useTheme();

  useEffect(() => {
    setActive(pathname.substring(1));
  }, [pathname]);

  const handleSubItemClick = (path) => {
    if (!path) {
      // Si no hay un "path" definido, solo cierra la barra lateral sin navegar
      setIsSidebarOpen(false);
    } else {
      // Si hay un "path" definido, navega
      navigate(path);
    }
  };

  const handleListItemClick = (text, subItems) => {
    const lcText = eliminatildes(text).toLowerCase();
    setActive(lcText);
    if (!subItems) {
      // Si no hay subelementos, navega al hacer clic en el elemento
      const path = `/${lcText}`;
      navigate(path);
      return;
    }
    setOpenItems((prevOpenItems) => {
      if (prevOpenItems.includes(lcText)) {
        return prevOpenItems.filter((item) => item !== lcText);
      } else {
        return [...prevOpenItems, lcText];
      }
    });
    setIsSidebarOpen(true); // Mantener el Sidebar visible después de hacer clic
  };

  return (
    <Box component="nav">
      {isSidebarOpen && (
        <Drawer
          open={isSidebarOpen}
          onClose={() => setIsSidebarOpen(false)}
          variant="persistent"
          anchor="left"
          sx={{
            width: drawerWidth,
            "& .MuiDrawer-paper": {
              color: theme.palette.secondary[200],
              backgroundColor: theme.palette.background.alt,
              boxSizing: "border-box",
              borderWidth: isNonMobile ? 0 : "2px",
              width: drawerWidth,
            },
          }}
        >
          <Box width="100%">
            <Box m="1.5rem 2rem 2rem 3rem">
              <FlexBetween color={theme.palette.secondary.main}>
                <Box display="flex" alignItems="center" gap="0.5rem">                
                  <img src="/home_logo_38.png" alt="Logo de prestaMu" />
                </Box>
                {!isNonMobile && (
                  <IconButton onClick={() => setIsSidebarOpen(!isSidebarOpen)}>
                    <ChevronLeft />
                  </IconButton>
                )}
              </FlexBetween>
            </Box>
            <List>
              {navItems.map(({ text, icon, subItems }) => {
                if (!icon) {
                  return (
                    <Typography
                      key={text}
                      sx={{
                        m: "2.25rem 0 1rem 3rem",
                        fontWeight: "bold",
                        fontSize: "1rem",
                        color: theme.palette.secondary[200],
                      }}
                    >
                      {text}
                    </Typography>
                  );
                }
                const lcText = eliminatildes(text).toLowerCase();
                const isOpen = openItems.includes(lcText);
                return (
                  <div key={text}>
                    <ListItemButton
                      onClick={() => handleListItemClick(text, subItems)}
                      sx={{
                        backgroundColor:
                          active === lcText
                            ? theme.palette.secondary[300]
                            : "transparent",
                        color:
                          active === lcText
                            ? theme.palette.primary[600]
                            : theme.palette.secondary[100],
                      }}
                    >
                      <ListItemIcon
                        sx={{
                          ml: "2rem",
                          color:
                            active === lcText
                              ? theme.palette.primary[600]
                              : theme.palette.secondary[200],
                        }}
                      >
                        {icon}
                      </ListItemIcon>
                      <ListItemText primary={text} />
                      {subItems && (
                        <IconButton size="small">
                          {isOpen ? (
                            <ChevronLeft fontSize="small" />
                          ) : (
                            <ChevronRightOutlined fontSize="small" />
                          )}
                        </IconButton>
                      )}
                    </ListItemButton>
                    {isOpen && subItems && (
                      <List sx={{ paddingLeft: "2.5rem" }}>
                        {subItems.map(({ text, path }) => (
                          <ListItemButton
                            key={text}
                            onClick={() => handleSubItemClick(path)}
                            sx={{ paddingLeft: "1rem" }}
                          >
                            <ListItemIcon>
                              <div
                                style={{
                                  width: "8px",
                                  height: "8px",
                                  borderRadius: "50%",
                                  backgroundColor: theme.palette.secondary.main,
                                }}
                              />
                            </ListItemIcon>
                            <ListItemText primary={text} />
                          </ListItemButton>
                        ))}
                      </List>
                    )}
                  </div>
                );
              })}
            </List>
          </Box>
        </Drawer>
      )}
    </Box>
  );
};

export default Sidebar;

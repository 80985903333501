import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const api = createApi({
  baseQuery: fetchBaseQuery({ baseUrl: process.env.REACT_APP_BASE_URL }),
  reducerPath: "adminApi",
  tagTypes: [
    "Usuario",
    "Tercero",
    "TerceroDOI",
    "Parametro",
    "SolicitudInsertarT",
    "SolicitudesDOI",
    "SolicitudInsertarT",
    "Prestamos",
    "PrestamosID",
    "Cuotas",
    "CuotaActualizar",
    "CuotaActualizarEs",
    "DniEx"
  ],

  endpoints: (build) => ({
    getUsuario: build.query({
      query: (id) => `general/usuario/${id}`,
      providesTags: ["Usuario"],
    }),
    getDOIex: build.query({
      query: ({ DOI }) => ({
        url: "tercero/buscar/dniex",
        method: "GET",
        params: { DOI },
      }),
      providesTags: ["DniEx"],
    }),
    getTercero: build.query({
      query: () => "tercero/buscar",
      providesTags: ["Tercero"],
    }),
    getTerceroDOI: build.query({
      query: ({ DOI }) => ({
        url: "tercero/buscar",
        method: "GET",
        params: { DOI },
      }),
      providesTags: ["TerceroDOI"],
    }),
    solicitudInsertarT: build.mutation({
      query: (solicitudData) => ({
        url: "solicitudes/insertarT",
        method: "POST",
        body: solicitudData,
      }),
      invalidatesTags: ["SolicitudInsertarT"], 
      providesTags: ["SolicitudInsertarT"],
    }),
    CondicionesInsertarT: build.mutation({
      query: (condicionesData) => ({
        url: "prestamos/insertar",
        method: "POST",
        body: condicionesData,
      }),
      invalidatesTags: ["CondicionesInsertarT"], // Invalida la caché de las solicitudes después de realizar la inserción
      providesTags: ["CondicionesInsertarT"],
    }),
    getParametros: build.query({
      query: ({ Campo1, Valor1, Campo2, Valor2 }) => ({
        url: "parametros/buscar",
        method: "GET",
        params: { Campo1, Valor1, Campo2, Valor2 },
      }),
      providesTags: ["Parametro"],
    }),
    getSolicitudes: build.query({
      query: ({ page, pageSize, DOI }) => ({
        url: "solicitudes/buscar",
        method: "GET",
        params: { page, pageSize, DOI },
      }),
      providesTags: ["Solicitudes"],
    }),
    getPrestamos: build.query({
      query: ({ page, pageSize, DOI }) => ({
        url: "prestamos/buscar",
        method: "GET",
        params: { page, pageSize, DOI },
      }),
      providesTags: ["Prestamos"],
    }),
    getPrestamosID: build.query({
      query: ({ page, pageSize, IdCond }) => ({
        url: "prestamos/buscar",
        method: "GET",
        params: { page, pageSize, IdCond },
      }),
      providesTags: ["PrestamosID"],
    }),
    getSolicitudesDOI: build.query({
      query: (doi) => `solicitudes/doi/${doi}`,
      providesTags: ["SolicitudesDOI"],
    }),
    getCuotas: build.query({
      query: (cuota) => ({
        url: "cuotas/buscar",
        method: "GET",
        params: cuota,
      }),
      providesTags: ["Cuotas"],
    }),
    CuotaActualizar: build.mutation({
      query: (cuota) => ({
        url: "cuotas/actualizar",
        method: "POST",
        body: cuota,
      }),
      invalidatesTags: ["CuotaActualizar"], 
      providesTags: ["CuotaActualizar"],
    }),
    CuotaActualizarEs: build.mutation({
      query: ({ IdCond, NumCuota, Estado }) => ({
        url: "cuotas/actualizar",
        method: "POST",
        params: { IdCond, NumCuota, Estado },
      }),
      invalidatesTags: ["CuotaActualizarEs"], 
      providesTags: ["CuotaActualizarEs"],
    }),
  }),
});

/* export const apiex = createApi({
  baseQuery: fetchBaseQuery({ baseUrl: process.env.REACT_APP_BUSQUEDA_DNI }),
  reducerPath: "externaApi",
  tagTypes: ["BuscarDNI"],
  endpoints: (build) => ({
    getDNIex: build.query({
      query: (DNI) =>
        `dni/${encodeURIComponent(DNI)}?token=${
          process.env.REACT_APP_TOKEN_DNI
        }`,
      providesTags: ["BuscarDNI"],
    }),
  }),
}); */

export const apiex = createApi({
  baseQuery: fetchBaseQuery({ baseUrl: process.env.REACT_APP_BUSQUEDA_DOI }),
  reducerPath: "externaApi",
  tagTypes: ["BuscarDNI"],
  endpoints: (build) => ({
    getDNIex: build.query({
      query: (DNI) =>
        `dni?numero=${encodeURIComponent(DNI)}`,
      providesTags: ["BuscarDNI"],
    }),
  }),
}); 

export const { useGetDNIexQuery } = apiex;

export const {
  useGetUsuarioQuery,
  useGetDOIexQuery,
  useGetTerceroQuery,
  useGetTerceroDOIQuery,
  useGetParametrosQuery,
  useGetSolicitudesQuery,
  useGetSolicitudesDOIQuery,
  useGetPrestamosQuery,
  useGetPrestamosIDQuery,
  useGetCuotasQuery,
  useSolicitudInsertarTMutation,
  useCondicionesInsertarTMutation,
  useCuotaActualizarMutation,
  useCuotaActualizarEsMutation,
} = api;

import { forwardRef } from 'react';
import { Icon } from '@iconify/react';
import Box from '@mui/material/Box';

// ----------------------------------------------------------------------

// Eliminar la interfaz Props y usar directamente BoxProps e IconifyProps
// interface Props extends BoxProps {
//  icon: IconifyProps;
// }

const Iconify = forwardRef(({ icon, width = 20, sx, ...other }, ref) => (
 <Box
 ref={ref}
 component={Icon}
 className="component-iconify"
 icon={icon}
 sx={{ width, height: width, ...sx }}
 {...other}
 />
));

export default Iconify;

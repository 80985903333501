export function eliminatildes(text) {
    const accents = [    ['á', 'a'],
      ['é', 'e'],
      ['í', 'i'],
      ['ó', 'o'],
      ['ú', 'u'],
      ['ü', 'u']
    ];
    return accents.reduce((acc, [accentedChar, unaccentedChar]) => {
      return acc.replace(new RegExp(accentedChar, 'ig'), unaccentedChar);
    }, text);
  }
import * as React from "react";
import {
  Box,
  useTheme,
  Button,
  Card,
  Stack,
  Divider,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  InputAdornment,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import {
  useGetCuotasQuery,
  useGetParametrosQuery,
  useCuotaActualizarEsMutation,
} from "state/api";
import Header from "components/Header";
import PrestamosAnalytic from "../../sections/cobranza/prestamos-analytic";
import Iconify from "components/iconify";
import SearchIcon from "@mui/icons-material/Search";

const Cobranza = () => {
  const theme = useTheme();
  const { data: EstadoData } = useGetParametrosQuery({
    Campo1: "Etiqueta",
    Valor1: "ESTADO",
    Campo2: "IdPadre",
    Valor2: "12",
  });

  const [paginationModel, setPaginationModel] = React.useState({
    page: 0,
    pageSize: 20,
    IdCond: "",
    DOI: "",
    Estado: "",
    Anio: "",
    Mes: "",
    Dia: "",
  });

  const [filtros, setBusqueda] = React.useState({
    DOI: "",
    Anio: "",
    Mes: "",
    Dia: "",
  });
  const { isLoading, data, refetch } = useGetCuotasQuery(paginationModel);

  const [rowCountState, setRowCountState] = React.useState(
    data?.totalRowCount || 0
  );
  React.useEffect(() => {
    setRowCountState((prevRowCountState) =>
      data?.totalRowCount !== undefined
        ? data?.totalRowCount
        : prevRowCountState
    );
  }, [data?.totalRowCount, setRowCountState]);

  const columns = [
    {
      field: "RowNum",
      headerName: "#",
      flex: 0.1,
    },
    {
      field: "IdCond",
      headerName: "ID",
      flex: 0.1,
    },
    {
      field: "FecPago",
      headerName: "Fec. Pago",
      flex: 0.2,

      renderCell: (params) =>
        new Date(params.value).toLocaleDateString("es-ES", {
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
        }),
    },
    {
      field: "DOI",
      headerName: "DOI",
      flex: 0.2,
    },
    {
      field: "Nombres",
      headerName: "Nombres",
      flex: 0.25,
    },
    {
      field: "ApePat",
      headerName: "Ape. Paterno",
      flex: 0.2,
    },
    {
      field: "ApeMat",
      headerName: "Ape. Materno",
      flex: 0.2,
    },
    {
      field: "NumCuota",
      headerName: "# Cuota",
      flex: 0.2,
    },
    {
      field: "MtoCuota",
      headerName: "Mto. Cuota.",
      flex: 0.2,
    },

    {
      field: "MtoSaldo",
      headerName: "Saldo",
      flex: 0.2,
    },
    {
      field: "actions",
      headerName: "Acción",
      flex: 0.2,
      renderCell: (params) => {
        let buttonColor = "";
        let buttonText = "";
        let buttonDisabled = false;
        switch (params.row.Estado) {
          case "ACT":
            buttonColor = "warning";
            buttonText = "Pagar";
            break;
          case "VEN":
            buttonColor = "error";
            buttonText = "Pagar";
            break;
          case "COB":
            buttonColor = "success";
            buttonText = "Cobrado";
            buttonDisabled = true;
            break;
          default:
            buttonColor = "error";
            buttonText = "Anulado";
            buttonDisabled = true;
        }
        return (
          <Button
            variant="contained"
            size="small"
            color={buttonColor}
            onClick={PagarFun(params.row.IdCond, params.row.NumCuota)}
            disabled={buttonDisabled}
          >
            {buttonText}
          </Button>
        );
      },
    },
  ];

  let rows = [];
  if (data) {
    rows = data.records;
  }

  const Filtrar = () => {
    setPaginationModel({
      ...paginationModel,
      DOI: filtros.DOI,
      Anio: filtros.Anio,
      Mes: filtros.Mes,
      Dia: filtros.Dia,
    });
  };
  const [CuotaActualizar] = useCuotaActualizarEsMutation();

  const PagarFun = (IdCond, NumCuota) => () => {
    const Estado = "COB";
    CuotaActualizar({ IdCond, NumCuota, Estado });
    refetch();
  };

  const filtrosFun = (e) => {
    const { name, value } = e.target;
    setBusqueda((prev) => ({ ...prev, [name]: value }));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setPaginationModel((prev) => ({ ...prev, [name]: value }));
  };
  return (
    <Box m="1.5rem 2.5rem">
      <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
        <Box sx={{ flexGrow: 1 }}>
          <Header title="Cobranza" subtitle="Lista de cuotas" />
        </Box>
        <Box sx={{ flexShrink: 0 }}></Box>
      </Box>
      <br></br>
      <Card
        sx={{
          mb: { xs: 3, md: 2 },
        }}
      >
        <Stack
          direction="row"
          divider={
            <Divider
              orientation="vertical"
              flexItem
              sx={{ borderStyle: "dashed" }}
            />
          }
          sx={{ py: 2 }}
        >
          <PrestamosAnalytic
            title="Total"
            total={100}
            percent={100}
            price={1000}
            icon="solar:bill-list-bold-duotone"
            color={theme.palette.info.main}
          />
          <PrestamosAnalytic
            title="Pagados"
            total={60}
            percent={60}
            price={600}
            icon="solar:file-check-bold-duotone"
            color={theme.palette.success.main}
          />

          <PrestamosAnalytic
            title="Pendiente"
            total={40}
            percent={40}
            price={400}
            icon="solar:sort-by-time-bold-duotone"
            color={theme.palette.warning.main}
          />

          <PrestamosAnalytic
            title="Vencidos"
            total={5}
            percent={5}
            price={50}
            icon="solar:bell-bing-bold-duotone"
            color={theme.palette.error.main}
          />
        </Stack>
      </Card>
      <Card
        sx={{
          mb: { xs: 3, md: 0.2 },
        }}
      >
        <Stack
          spacing={2}
          alignItems={{ xs: "flex-end", md: "center" }}
          direction={{
            xs: "column",
            md: "row",
          }}
          sx={{
            p: 2.5,
            pr: { xs: 2.5, md: 1 },
          }}
        >
          <FormControl
            sx={{
              flexShrink: 0,
              width: { xs: 1, md: 180 },
            }}
          >
            <InputLabel id="Estado-label">Estado</InputLabel>
            <Select
              labelId="Estado-label"
              name="Estado"
              value={paginationModel.Estado || ""}
              onChange={handleChange}
            >
              {EstadoData && EstadoData.length > 0 ? (
                EstadoData.map((parametro) => (
                  <MenuItem key={parametro.IdParametro} value={parametro.Valor}>
                    {parametro.Descripcion}
                  </MenuItem>
                ))
              ) : (
                <MenuItem value="">Cargando...</MenuItem>
              )}
            </Select>
          </FormControl>
          <TextField
            placeholder="Año"
            name="Anio"
            onChange={filtrosFun}
          ></TextField>

          <TextField
            placeholder="Mes"
            name="Mes"
            onChange={filtrosFun}
          ></TextField>
          <Stack
            direction="row"
            alignItems="center"
            spacing={2}
            flexGrow={1}
            sx={{ width: 1 }}
          >
            <TextField
              placeholder="Día"
              name="Dia"
              onChange={filtrosFun}
            ></TextField>
            <TextField
              fullWidth
              placeholder="Busca por DOI"
              name="DOI"
              onChange={filtrosFun}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Iconify
                      icon="eva:search-fill"
                      sx={{ color: "text.disabled" }}
                    />
                  </InputAdornment>
                ),
              }}
            />
            <Button
              sx={{ marginRight: 1, minWidth: "auto" }}
              variant="contained"
              size="large"
              color="info"
              onClick={Filtrar}
              startIcon={<SearchIcon />}
            >
              Buscar
            </Button>
          </Stack>
        </Stack>
      </Card>
      <Box
        height="60vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: theme.palette.primary.light,
          },
          "& .MuiDataGrid-footerContainer": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderTop: "none",
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${theme.palette.secondary[200]} !important`,
          },
        }}
      >
        <DataGrid
          columns={columns}
          rows={rows}
          getRowId={(row) => row.RowNum}
          rowCount={rowCountState}
          loading={isLoading}
          pageSizeOptions={[5, 10, 20]}
          paginationModel={paginationModel}
          paginationMode="server"
          onPaginationModelChange={setPaginationModel}
        />
      </Box>
    </Box>
  );
};

export default Cobranza;

import { useState } from "react";
import "../Login.css";

const Login = ({ setUser, setSecret }) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const handleLogin = () => {
    if (
      username === process.env.REACT_APP_USERNAME1 &&
      password === process.env.REACT_APP_PASSWORD1
    ) {
      setUser(username);
      setSecret(password);
      alert("¡Inicio de sesión exitoso!");
    } else {
      alert("Credenciales incorrectas. Por favor, inténtelo de nuevo.");
    }
  };

  return (
    <div className="login-container">
      <h2 className="login-title">Iniciar sesión</h2>
      <div className="login-form">
        <input
          className="login-input"
          type="text"
          placeholder="Nombre de usuario"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />
        <input
          className="login-input"
          type="password"
          placeholder="Contraseña"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <button className="login-button" type="button" onClick={handleLogin}>
          Ingresar
        </button>
      </div>
    </div>
  );
};

export default Login;

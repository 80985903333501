import React from "react";
import { Box } from "@mui/material";
import Header from "components/Header";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";


const SignupForm = () => {
    
  const schema = yup.object().shape({
    firstName: yup.string().required("El nombre es obligatorio"),
    lastName: yup.string().required(),
    age: yup.number().positive().integer().required(),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = (data) => console.log(data);

  return (
    <Box m="1.5rem 2.5rem">
      <Header title="CLIENTES" subtitle="Nuevo Cliente" />
      <Box>
        <form onSubmit={handleSubmit(onSubmit)}>
          <label className={errors.firstName ? "error" : ""}>First Name</label>
          <input
            name="firstName"
            {...register("firstName")}
            className={errors.firstName ? "error" : ""}
          />
          {errors.firstName && <p>{errors.firstName.message}</p>}

          <label className={errors.lastName ? "error" : ""}>Last Name</label>
          <input
            name="lastName"
            {...register("lastName")}
            className={errors.lastName ? "error" : ""}
          />
          {errors.lastName && <p>{errors.lastName.message}</p>}

          <label className={errors.age ? "error" : ""}>Age</label>
          <input
            name="age"
            type="number"
            {...register("age")}
            className={errors.age ? "error" : ""}
          />
          {errors.age && <p>{errors.age.message}</p>}

          <input type="submit" />
        </form>
      </Box>
    </Box>
  );
};

export default SignupForm;
